.sidebar {
  position: fixed;
  padding: 25px 10px;
  width: 200px;
  margin: 20px;
  color: var(--color-text);
}
.sidebar > div {
  /* background-color: var(--color-background-light);
  box-shadow: 0 5px 17px -7px var(--color-primary); */
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 8px;
}

.sidebar > div:first-child {
  width: 250px;
  text-transform: uppercase;
}
/* .sidebar > div:hover {
  background-color: var(--color-background-light) !important;
  color: var(--color-secondary);
} */
