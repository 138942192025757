.header {
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;
  position: sticky;
  z-index: 100;
  top: 0;
   background-color: var(--color-background-light);
  /*box-shadow: 0 5px 8px -9px var(--color-primary); */
}

.header__left {
  display: flex;
  justify-content: space-evenly;
}

.header__input {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-left: 10px;
  background-color: var(--color-background-dark);
  border-radius: 999px;
  color: var(--color-primary);
}

.header__input > input {
  border: none;
  background-color: transparent;
  outline-width: 0;
}

.header__left > img {
  height: 50px;
}

.header__center {
  display: flex;
  flex: 1;
  justify-content: center;
}

.header__option > .MuiSvgIcon-root {
  color: gray;
}

.header__option:hover > .MuiSvgIcon-root {
  color: var(--color-primary) !important;
}

.header__option--active {
  border-bottom: 4px solid var(--color-primary);
}

.header__option--active > *{
  color: var(--color-primary);
}

.header__option {
  display: flex;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;
}

.header__option:hover {
   background-color: var(--color-background-dark);
 /* box-shadow: 0 5px 17px -7px var(--color-primary); */
  border-radius: 10px;
  align-items: center;
  padding: 0 30px;
  border-bottom: none;
}

.header__info:hover {
  
  border-radius: 10px;
  /* background-color: var(--color-background-dark);
  box-shadow: 0 5px 17px -7px var(--color-primary); */
}

.header__info:first-child:hover {
  color: var(--color-secondary);
}

.header__info {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.header__info > h4 {
  margin-left: 10px;
}

.header__right {
  display: flex;
  color: var(--color-text);
}

.header__right__icons {
  color: gray;
}

.header__right__icons:hover {
  color: var(--color-primary);
}
