.dropdown {
  position: relative;
  top: -40px;
  left: 55px;
}

.dropdown .MuiSvgIcon-root {
  color: var(--color-primary);
}

.slide {
  clear: both;
  width: 200px;
  height: 0px;
  overflow: hidden;
  text-align: left;
  transition: height 0.4s ease;
  /*  box-shadow: 0 5px 7px -7px var(--color-primary); */
}

.slide li {
  padding-top: 15px;
  margin-left: -30px;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked + .slide {
  height: 200px;
}

li {
  list-style-type: none;
  color: var(--color-text);
}
