html {
  --color-text: white;
  --color-background-dark: #26232b;
  --color-background-light: #322f36;
  --color-primary: #8675f0;
  --color-secondary: #09eeb7;
}

a:active {
  color: var(--color-secondary);
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

a:visited,
a h4 {
  color: var(--color-text);
}

.app {
  background-color: var(--color-background-dark);
  min-height: 100vh;
}

.app__body {
  margin: 0 auto;
}
