.sidebarRow {
  display: flex;
  align-items: center;
  padding: 10px;
}

.sidebarRow h4 {
  margin-left: 15px;
  font-weight: 600;
}
.avatar{
  border: 5px solid var(--color-primary);
}

.sidebarRow .MuiSvgIcon-root{
    font-size: xx-large;
    color: var(--color-primary);
}