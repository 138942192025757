.post {
  width: 700px;
  margin-top: 15px;
  border-radius: 15px;
   background-color: var(--color-background-light);
 /* box-shadow: 0 5px 7px -7px var(--color-primary); */
}

.post__top {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}
.post__top__left {
  display: flex;
  position: relative;
  align-items: center;
}

.post__avatar {
  margin-right: 10px;
}

.post__top__right__text {
  color: var(--color-text);
  font-size: x-small;
  text-align: center;
  padding: 2px;
}

.post__icons__container {
  display: flex;
  padding: 1px;
  padding-bottom: 5px;
  align-items: center;
}

.post__source__image,
.post__game__image {
  width: 35px;
  cursor: pointer;
  margin: 2px;
}

.post__topInfo > h3 {
  font-size: medium;
}

.post__topInfo > p {
  font-size: small;
  color: gray;
}

.post__bottom {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 25px;
}
.post__image__container {
  width: 700px;
  position: absolute;
  height: 480px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  z-index: 0;
}
.post__image {
  display: flex;
  justify-content: center;
}

.post__image > img {
  position: relative;
  max-width: 100%;
  height: 480px;
  z-index: 1;
}

.post__video {
  width: 100%;
  z-index: 1;
  position: relative;
}

.post__options {
  border-top: 1px solid var(--color-background-dark);
  color: gray;
  cursor: pointer;
  display: flex;
  font-size: medium;
  justify-content: space-evenly;
  padding: 15px;
}
.post__option {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
  padding: 5px;
}

.CommentText > div > button {
  border: none;
  color: gray;
  height: 30px;
  width: 91%;
  background-color: var(--color-background-dark);
  cursor: pointer;
  margin:  0 20px;
  border-radius: 8px;
}
.CommentText > div  > button:hover {
  /* box-shadow: 0 5px 17px -7px var(--color-primary); */
  color: var(--color-secondary);
}

.post__option > p {
  margin-left: 10px;
}

.post__option__comment {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0;
  height: 0;
  overflow: hidden;
}
.comment__text{
  padding: 10px;
}

.post__option__comment > input {
  height: 200px;
  width: 90%;
  border-radius: 5px;
  margin: 20px;
  color: var(--color-text);
  background-color: var(--color-background-dark);
}

.post__option:hover {
   background-color: var(--color-background-dark);
 /* box-shadow: 0 5px 7px -7px var(--color-primary); */
  border-radius: 10px;
  color: var(--color-secondary);
}
.post__option:hover > p {
  color: var(--color-secondary);
}

.post__html {
  padding: 20px;
}
