.story {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 120px;
  height: 200px;
  border-radius: 10px;
  margin: 12px;
  transition: transform 100ms, easy-in;
  cursor: pointer;
}

.story:hover {
  transform: scale(1.07);
}

.story__avatar {
  border: 5px solid var(--color-primary);
}

.story h4,
.story p {
  text-align: center;
}

.story p {
  font-size: smaller;
}

.story__overlay {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.story__overlay:hover {
  background-color: transparent;
}