.owl-theme{
  margin-left: -15px;
}
.owl-stage-outer {
  height: 250px;
  width: 711px;
}
.owl-prev {
  position: absolute !important;
  top: 9px;
  left: -20px;
  height: 200px;
  width: 15px;
}

.owl-next {
  position: absolute !important;
  top: 9px;
  right: -33px;
  height: 200px;
  width: 15px;
}
.owl-prev:hover,
.owl-next:hover {
   background-color: var(--color-primary) !important;
 /* box-shadow: 0 5px 17px -3px var(--color-primary); */
}
